<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>合同管理</el-breadcrumb-item>
      <el-breadcrumb-item>客户信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div style="display: flex">
        <el-button style="margin-right: 15px" type="primary" @click="opentan()">添加信息</el-button>
        <el-input style="width: 300px" placeholder="请输入内容" v-model="sosuotext" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="sosuofun()"></el-button>
        </el-input>
      </div>

      <!--    列表开始 -->
      <el-table :data="tableData" stripe style="width: 100%    text-align: center">
        <el-table-column align="center" prop="no" label="客户编号" width="120"></el-table-column>
        <el-table-column align="center" prop="name" label="客户名称" width="150"></el-table-column>
        <el-table-column align="center" prop="type_name" label="客户类型"></el-table-column>
        <el-table-column align="center" prop="lv" label="客户等级"></el-table-column>
        <el-table-column align="center" prop="cooperate_count" label="累计合作次数"></el-table-column>
        <el-table-column align="center" prop="contract_amount" label="累计合同金额"></el-table-column>
        <el-table-column align="center" prop="unliquidated_amount" label="未结账款金额"></el-table-column>
        <el-table-column align="center" prop="overtime_amount" label="逾期账款金额"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row.id)"></el-button>
            <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />

      <!-- 添加用户弹窗 -->
      <el-dialog title="客户添加信息" :visible.sync="dialogVisible" width="50%"  :before-close="handleClose">
        <!-- 客户名称 -->
        <el-form ref="ruleFrom" :model="ruleFrom" label-width="100px" :rules="drules">
          <el-row type="flex" class="row-bg" justify="space-around ">
            <el-col :span="8">
              <el-form-item label="客户名称" prop="name">
                <div class="el-select"><el-input v-model="ruleFrom.name" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
            <!--客户类型-->
            <el-col :span="8">
              <el-form-item label="客户类型" prop="type">
                <el-select v-model="ruleFrom.type">
                  <el-option v-for="item in trashtypeData" :key="item.id" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 客户联系人 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="客户联系人">
                <div class="el-select"><el-input v-model="ruleFrom.linkman" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>

            <!--客户联系方式-->
            <el-col :span="8">
              <el-form-item label="联系方式">
                <el-input type="text" v-model="ruleFrom.contact" placeholder="客户联系方式"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 级联地区 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="客户地址" prop="area">
                <el-cascader ref="tree" @change="optpidfun" v-model="ruleFrom.area" :options="usetypelist" clearable :props="casprops"></el-cascader>
              </el-form-item>
            </el-col>

            <!--客户详细地址-->
            <el-col :span="8">
              <el-form-item label="详细地址">
                <el-input type="text" v-model="ruleFrom.contact_address" placeholder="例**街道**路**号**楼**号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="固定电话">
                <el-input type="text" v-model="ruleFrom.contact_phone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售负责人">
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="ruleFrom.sale_user_name"
                  :fetch-suggestions="salesperson"
                  value-key="name"
                  @select="XiaohandleSelect($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="售后负责人">
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="ruleFrom.after_sale_user_name"
                  :fetch-suggestions="validateCountss"
                  value-key="name"
                  @select="ShouhandleSelect($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="运营负责人">
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="ruleFrom.operate_user_name"
                  :fetch-suggestions="validateCountsss"
                  value-key="name"
                  @select="YunhandleSelect($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="center">
            <el-form-item>
              <el-button @click="cancelfun(ruleFrom)">取 消</el-button>
              <el-button type="primary" @click="submitForm('ruleFrom')">添加</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 修改用户弹窗 -->
      <el-dialog title="客户信息界面" :visible.sync="enddialogVisible" width="50%">
        <el-form ref="Detail" :model="Detail" label-width="100px" :rules="Detaildrules">
          <div class="top">客户详细信息</div>
          <el-row type="flex" class="row-bg" justify="space-around ">
            <!-- 客户编号 -->
            <el-col :span="8">
              <el-form-item label="客户编号">
                <div class="el-select"><el-input v-model="Detail.no" disabled></el-input></div>
              </el-form-item>
            </el-col>
            <!-- 客户名称 -->

            <el-col :span="8">
              <el-form-item label="客户名称" prop="name">
                <div class="el-select"><el-input v-model="Detail.name" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 客户类型 -->
          <el-row type="flex" class="row-bg" justify="space-around ">
            <el-col :span="8">
              <el-form-item label="客户类型" prop="type">
                <el-select v-model="Detail.type">
                  <el-option v-for="item in trashtypeData" :key="item.id" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!--客户联系人-->
            <el-col :span="8">
              <el-form-item label="客户联系人">
                <div class="el-select"><el-input v-model="Detail.linkman" placeholder="请输入内容"></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 客户联系方式 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="联系方式">
                <el-input type="text" v-model="Detail.contact" placeholder="客户联系方式"></el-input>
              </el-form-item>
            </el-col>

            <!-- 客户地区 -->
            <el-col :span="8">
              <el-form-item label="客户地址">
                <el-cascader ref="tree" @change="optpidfuns" v-model="Detail.area" :options="usetypelist" clearable :props="casprops"></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 客户详细地址 -->
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="详细地址">
                <el-input type="text" v-model="Detail.contact_address" placeholder="例**街道**路**号**楼**号"></el-input>
              </el-form-item>
            </el-col>

            <!--客户固定电话-->
            <el-col :span="8">
              <el-form-item label="固定电话">
                <el-input type="text" v-model="Detail.contact_phone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="销售负责人">
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="Detail.sale_user_name"
                  :fetch-suggestions="endsalesperson"
                  value-key="name"
                  @select="Xiaoend($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="售后负责人">
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="Detail.after_sale_user_name"
                  :fetch-suggestions="endvalidateCountss"
                  value-key="name"
                  @select="Shouend($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="运营负责人">
                <el-autocomplete
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  v-model="Detail.operate_user_name"
                  :fetch-suggestions="endvalidateCountsss"
                  value-key="name"
                  @select="Yunend($event, '负责人')"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>

          <!-- 按钮 -->
          <el-row type="flex" class="row-bg" justify="center">
            <el-form-item>
              <el-button @click="cancelfuns()">取 消</el-button>
              <el-button type="primary" @click="amend('Detail')">确认修改</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Drop, Getlist, Fuzeren, ClientInformation, Customersdata, Compile, Area } from '../../../api/pact'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      dialogVisible: false, // 添加弹窗控制
      enddialogVisible: false, // 修改信息弹窗控制
      sosuotext: '', // 搜索
      ruleFrom: {
        // 添加数据时候的数据
        name: '', // 客户姓名
        type: '', // 客户类型
        linkman: '', // 客户联系人
        contact: '', // 客户手机号
        area: '', // 客户地区编码
        contact_address: '', // 客户详细地址
        contact_phone: '', // 固定电话
        sale_user_name: '', // 销售负责人
        sale_user: '', // 销售负责人id
        after_sale_user_name: '', // 售后负责人
        after_sale_user: '', // 售后负责人id
        operate_user_name: '', // 运营负责人
        operate_user: '' // 运营负责人id
      },

      usetypelist: [], // 地区级联地区数据
      casprops: { value: 'code', label: 'area_name', checkStrictly: false, children: 'list' }, // 地区级联选择的配置

      // 验证规则
      drules: {
        type: [{ required: true, message: '请选择客户类型', trigger: 'change' }],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, message: '请填写正确格式', trigger: 'blur' }
        ],
        // linkman: [
        //   { required: true, message: '请输入客户联系人', trigger: 'blur' },
        //   { min: 2, max: 5, message: '请填写正确格式', trigger: 'blur' }
        // ],
        site: [
          { required: true, message: '请输入地区关键字', trigger: 'blur' },
          { min: 2, message: '请填写正确格式', trigger: 'blur' }
        ],
        contact_address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 2, message: '请填写详细地址', trigger: 'blur' }
        ],
        // contact: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        // ],
        contact_phone: [{ required: true, message: '请输入正确的格式', trigger: 'blue' }],
        area: [{ required: true, message: '请选择地址', trigger: 'blur' }]
      },
      // 修改信息验证
      Detaildrules: {
        type: [{ required: true, message: '请选择客户类型', trigger: 'change' }],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, message: '请填写正确格式', trigger: 'blur' }
        ],
        // linkman: [
        //   { required: true, message: '请输入客户联系人', trigger: 'blur' },
        //   { min: 2, max: 5, message: '请填写正确格式', trigger: 'blur' }
        // ],
        site: [
          // { required: true, message: '请输入地区关键字', trigger: 'blur' },
          { min: 3, max: 50, message: '请填写正确格式', trigger: 'blur' }
        ],
        contact_address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 2, message: '请填写详细地址', trigger: 'blur' }
        ],
        // contact: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        // ],
        contact_phone: [{ required: true, message: '请输入正确的格式', trigger: 'blue' }]
      },
      userlist: [],
      trashtypeData: '', // 客户类型
      tableData: [], // 客户列表
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 客户详情信息
      Detail: {
        id: '',
        no: '', // 客户编号
        contact: '', // 客户电话
        contact_address: '', // 客户详细地址
        contact_phone: '', // 客户固定电话
        linkman: '', // 客户联系人
        name: '', // 客户名称
        type: '', // 客户类型
        area: '', // 客户地区编码
        sale_user: '', // 销售负责人id
        sale_user_name: '', // 销售负责人
        after_sale_user_name: '', // 售后负责人
        after_sale_user: '', // 售后负责人id
        operate_user_name: '', // 运营负责人
        operate_user: '' // 运营负责人id
      }
    }
  },
  mounted() {
    // 拉取客户列表
    this.Getlists()
    this.getFoot() // 客户类型下拉框
    this.Areas()
  },

  methods: {
    sosuofun(){

    },
    // 添加数据
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 成功
          this.ClientInformations(this.ruleFrom) // 添加用户信息
          this.Getlists() // 添加成功之后重新拉取列表
          // this.ruleFrom = {}
          this.$refs[formName].resetFields()
        }
      })
    },
    // 弹窗开关
    opentan() {
      this.dialogVisible = true
      // this.getFoot() // 客户类型下拉框
      // this.Areas()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlists()
    },
    bianye(newPape) {
      this.pageData.page = newPape // 分页页数变化
      this.Getlists() // 获取列表
    },
    // 搜索销售责人后的数据
    salesperson(queryString, cb) {
      this.Fuzerens(queryString, cb)
    },
    // 搜索售后责人后的数据
    validateCountss(queryString, cb) {
      this.Fuzerens(queryString, cb)
    },
    // 搜索运维责人后的数据
    validateCountsss(queryString, cb) {
      this.Fuzerens(queryString, cb)
    },
    // 销售选中
    XiaohandleSelect(e, term) {
      // console.log(e);
      this.ruleFrom.sale_user = e.value
      console.log(this.ruleFrom.sale_user)
    },
    // 售后选中
    ShouhandleSelect(e, term) {
      // console.log(e);
      this.ruleFrom.after_sale_user = e.value
      console.log(this.ruleFrom.after_sale_user)
    },

    // 运营选中
    YunhandleSelect(e, term) {
      // console.log(e);
      this.ruleFrom.operate_user = e.value
      console.log(this.ruleFrom.operate_user)
    },
    /////////////////////////////////////////////////////
    // 搜索销售责人后的数据
    endsalesperson(queryString, cb) {
      this.Fuzerens(queryString, cb)
    },
    // 搜索售后责人后的数据
    endvalidateCountss(queryString, cb) {
      this.Fuzerens(queryString, cb)
    },
    // 搜索运维责人后的数据
    endvalidateCountsss(queryString, cb) {
      this.Fuzerens(queryString, cb)
    },
    // 销售选中
    Xiaoend(e, term) {
      // console.log(e);
      this.Detail.sale_user = e.value
      console.log(this.Detail.sale_user)
    },
    // 售后选中
    Shouend(e, term) {
      // console.log(e);
      this.Detail.after_sale_user = e.value
      console.log(this.Detail.after_sale_user)
    },

    // 运营选中
    Yunend(e, term) {
      // console.log(e);
      this.Detail.operate_user = e.value
      console.log(this.Detail.operate_user)
    },
    // 添加信息取消
    cancelfun(ruleFrom) {
      // this.Getlists()
      this.dialogVisible = false
      this.enddialogVisible = false
      this.$refs.ruleFrom.resetFields()
      this.ruleFrom.linkman = ''
      this.ruleFrom.contact = ''
      this.ruleFrom.sale_user_name = '' // 销售负责人id
      this.ruleFrom.after_sale_user_name = '' // 售后负责人id
      this.ruleFrom.operate_user_name = '' // 运营负责人id
      this.ruleFrom.sale_user = '' // 销售负责人id
      this.ruleFrom.after_sale_user = '' // 售后负责人id
      this.ruleFrom.operate_user = '' // 运营负责人id
      this.ruleFrom.contact_phone = ''
      this.ruleFrom.contact_address=''
    },
    // 修改信息取消
    cancelfuns() {
      this.enddialogVisible = false
    },
    // 关闭添加弹窗
    handleClose() {
      this.dialogVisible = false
      this.cancelfun()
    },
    // 查看客户信息
    editfun(ev) {
      this.Customersdatas(ev) // 点击修改按钮 拉取客户详情
      this.enddialogVisible = true // 修改信息弹窗
      this.getFoot() // 客户类型下拉框
      this.Areas()
    },
    // 确认修改
    amend(Detail) {
      this.$refs[Detail].validate(valid => {
        if (valid) {
          console.log(valid)
          this.Compiles(this.Detail)
          this.enddialogVisible = false // 成功之后关闭弹窗
          this.Getlists() // 重新拉取列表
        } else {
        }
      })
    },
    // 获取客户地区编码
    optpidfun(e) {
      this.ruleFrom.area = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
    },
    optpidfuns(e) {
      this.Detail.area = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
    },

    // 请求
    // 客户类型下拉框
    async getFoot() {
      Drop().then(res => {
        this.trashtypeData = res.data.data
      })
    },

    // 获取列表
    async Getlists() {
      const { data } = await Getlist(this.pageData)
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.getanpostfun(data, '获取')
    },

    // 添加客户信息
    async ClientInformations(v) {
      const { data } = await ClientInformation(v)
      this.getanpostfun(data, '添加',this.Getlists(),this.handleClose())
    },

    // 拉取客户详情信息
    async Customersdatas(id) {
      const {
        data: { data: res }
      } = await Customersdata({ id })
      console.log(res)
      this.Detail.no = res.info.no // 客户编号
      this.Detail.contact = res.info.contact // 客户电话
      this.Detail.contact_address = res.info.contact_address // 客户详细地址
      this.Detail.contact_phone = res.info.contact_phone // 客户固定电话
      this.Detail.linkman = res.info.linkman // 客户联系人
      this.Detail.name = res.info.name // 客户名称
      this.Detail.type = res.info.type // 客户类型
      this.Detail.area = res.info.area // 客户地区
      this.Detail.sale_user = res.sale_user.user_id // 销售负责人id
      this.Detail.sale_user_name = res.sale_user.name
      this.Detail.after_sale_user_name = res.after_sale_user.name // 售后负责人
      this.Detail.after_sale_user = res.after_sale_user.user_id
      this.Detail.operate_user_name = res.operate_user.name //运营负责人
      this.Detail.operate_user = res.operate_user.user_id
      this.Detail.id = res.info.id
    },
    // 地区联动
    async Areas() {
      const { data } = await Area()
      this.usetypelist = data.data.list
    },

    // 添加客户信息
    async Compiles(v) {
      const { data } = await Compile(v)
      this.getanpostfun(data, '修改')
    },
    // 业务负责人
    async Fuzerens(name, cd) {
      const { data } = await Fuzeren({ name })
      // console.log(data.data)
      this.userlist = data.data
      cd(data.data)
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
.top {
  margin: 0 auto;
  width: 50%;
  height: 40px;
  line-height: 40px;
  /* border: 1px solid #999; */
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
}
</style>
